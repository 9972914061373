import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ContentWrapper from "../components/ContentWrapper";
import twemoji from 'twemoji'

const H1 = styled.h1`
  color: #d2eaea;
`;

const HeaderTag = styled.header`
  width: 100%;
  padding: 16px 0;
  // border-bottom: solid 1px ${props => props.theme.colors.blackLight};
  box-shadow: 0 2px 4px rgba(0,0,0,.4);
`;

const HeaderInner = styled.div`
  position: relative;
  h1 {
    font-size: 24px;
  },
  h3 {
    width: 100%;
  }
  .logo {
    display: block;
    width: 165px;
    height: 37px;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      margin: 0 auto;
    }
  }

  .logo-link {
    display: block;
  }
  .message-link {
    position: absolute;
    right: 0;
    top: 7px;
    display: block;
    width: 34px;
    &:hover {
      top: 5px;
    }
  }
`;

const PostCardEmoji = styled.span`
  border-radius: 4px;
  font-size: 50px;
  margin-left: 2px;
  img {
    width: 55px;
    height: 55px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 70px;
    height: 70px;
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const HeaderLink = styled(Link)`
  display: flex !important;
  align-items: center;
`;

const Header = ({ title, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const titleEmoji = twemoji.parse("🍺", {
    folder: "svg",
    ext: ".svg"
  });
  const logoLink = (
    <HeaderLink to={`/`} className="logo-link">
      <H1>{title}</H1><PostCardEmoji dangerouslySetInnerHTML={{ __html: titleEmoji }} />
    </HeaderLink>
  );

  let headerLogo;
  if (location.pathname === rootPath) {
    headerLogo = <h1>{logoLink}</h1>;
  } else {
    headerLogo = <h3>{logoLink}</h3>;
  }
  return (
    <HeaderTag>
      <ContentWrapper>
        <HeaderInner>{headerLogo}</HeaderInner>
      </ContentWrapper>
    </HeaderTag>
  );
};

export default Header;
