const variables = {
  colors: {
    base: "#3092a2",
    background: "#1f2232",
    blackLight: "#1f6a77",
    blackLightCard: "#1 08989",
    gray: "#eee",
    silver: "#ee8221",
    whitesmoke: "#f5a930",
    highlight: "#0091ff",
    red: "#f7615f",
    orange: "#ffa22b",
    gradient: "linear-gradient(-45deg,#ffa649,#f7645b,#805ed4)"
  },
  sizes: {
    bioWidth: "290px",
    maxWidth: "1100px"
  },
  sideSpace: {
    small: "20px",
    large: "1.5em",
    contentSmall: "20px",
    contentLarge: "2.5em"
  },
  responsive: {
    small: "500px",
    medium: "768px",
    large: "950px"
  }
};

export default variables;
